import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Section from '../components/section';
import Gallery from '../components/gallery';

const Photos = ({ data }) => (
  <Layout>
    <Section>
      <div
        dangerouslySetInnerHTML={{
          __html: data.photosJson.content.childMarkdownRemark.html,
        }}
      />
      <Gallery items={data.photosJson.gallery} />
    </Section>
  </Layout>
);

Photos.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Photos;

export const query = graphql`
  query PhotosQuery {
    photosJson {
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
